export const environment = {
  debug: false,
  USEndpoint: 'https://api-qa.trycycle.io/client/',
  CAEndpoint: 'https://api-qa.trycycle.io/client/',
  buildNumber: "28726199",  
  versionNumber: "5.3.6",  
  environment_deploy: "qa",  
  rayGun: {
  apiKey: '19Ef2ju2ppmbEe8xOPBOA', 
  },
  posthogToken: 'phc_PuEnTqZCSS7VliKtYiAbPisu6SDBFXEfIzbqbnuYPMg', 
  authResultKey: 'c487cb1e-3f33-43f7-8a99-244cc9e259df', 
  vaultKey: '',
  loginKey: '',
  biometricsEmailKey: '',
  remeberEmailKey: '22e11274-33fc-46b5-89c2-21e524f4e542',
  cryptoKey: '1923f91c-7a20-4161-99bd-f7d1c5319852', 
};
